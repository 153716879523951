import React, { useEffect, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { Heading } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Page from 'components/Layout/Page'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { toggleTheme } from 'state/user/actions'

const StyledHero = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.textSubtle};
  margin-bottom: 24px;
  padding-bottom: 32px;
`

const Collectibles = () => {
  const { t } = useTranslation()
  const iframeUrl = 'https://mobula.io/chain/matchain/incaswap';

  const iframeRef = useRef(null);
   const dispatch = useDispatch<AppDispatch>()
   const { isDark } = useTheme()


   useEffect(() => {
    if (isDark) {
      dispatch(toggleTheme()); // Alterna o tema se não estiver no modo escuro
    }
  }, [dispatch]);

  return (
    <>
      <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <iframe
        src={iframeUrl}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        title="Info"
      ></iframe>
      {/* Cobrir o cabeçalho */}
      {/* <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100px', // Altura do cabeçalho a ser ocultado
          backgroundColor: 'white',
        }}
      ></div> */}
    </div>

    </>
  )
}

export default Collectibles
